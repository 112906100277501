import Error from "../../../../util/Error";

const DAMAGE_DOCUMENT_STATE = {
    token: null,
    id: null,
    document: null,
    title: null,
    error: false
};

export default {
    data() {
        return {
            damage_document: {...DAMAGE_DOCUMENT_STATE},
            damage_documents: [],
        }
    },
    methods: {
        handleAddUpdateDamageDocumentClick() {
            this.damage_document.error = false;
            this.formErrors = new Error({})
            let errors = {};

            if (!this.damage_document.document || this.damage_document.document.length <= 0 || !this.damage_document.document.id) {
                this.damage_document.error = true;
                errors.document = [this.$t('validation.required')];
            }

            if (!this.damage_document.title || _.trim(this.damage_document.title.length) <= 2) {
                this.damage_document.error = true;
                errors.title = [this.$t('validation.required')];
            }

            _.map(this.damage_documents, (document) => {
                if (document.token !== this.damage_document.token) {
                    if (document.title === this.damage_document.title) {
                        errors.title = [this.$t('validation.duplicate')];
                        this.damage_document.error = true;
                        this.formErrors = new Error(errors);
                        return false;
                    }
                }
            });

            this.formErrors = new Error(errors)
            if (this.damage_document.error) return false;

            const entity = this.damage_document;
            let index = -1;
            if (entity.token)
                index = _.findIndex(this.damage_documents, {token: entity.token});

            if (this.damage_documents[index] !== undefined) {
                this.damage_documents[index] = entity;
            } else {
                entity.token = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
                this.damage_documents.push(entity)
            }

            this.damage_document = {
                ...DAMAGE_DOCUMENT_STATE,
                token: Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            }
        },
        handleDeleteDamageDocumentClick(token) {
            const index = _.findIndex(this.damage_documents, {token: token})
            if (this.damage_documents[index] !== undefined) {
                this.damage_documents.splice(index, 1);
            }
        },
        handleEditDamageDocumentClick(token) {
            const index = _.findIndex(this.damage_documents, {token: token})
            if (this.damage_documents[index] !== undefined) {
                this.damage_document = {...this.damage_documents[index]};
            }
        },
        resetDamageDocument() {
            this.damage_document = {...DAMAGE_DOCUMENT_STATE}
            this.damage_document.error = false;
        },
    }
}
